<template>
  <div class="worker dataM">
    <el-table 
      :data="tableList" 
      header-cell-class-name="tableHeader" 
      style="width: 100%" 
      @selection-change="handleSelectionChange"
      @row-dblclick="handleEdit"
    >
      <el-table-column type="selection" width="55"/>
      <el-table-column label="序号" type="index" width="100" :index="getIndex"/>
      <el-table-column prop="staffStatus" label="状态"/>
      <el-table-column prop="staffNo" label="工号"/>
      <el-table-column prop="staffName" label="姓名"/>
      <el-table-column prop="staffDepartment" label="部门"/>
      <el-table-column prop="jobTitle" label="岗位技能"/>
      <el-table-column prop="workshiftSchemeName" label="日历方案"/>
      <el-table-column label="操作" width="300">
        <template slot-scope="scope">
          <div class="operation">
            <el-tooltip v-premiSub="'生产设备_编辑','工作人员_编辑'" effect="dark" content="编辑" placement="top">
              <img src="@/assets/images/dataManage/edit.png" style="margin-right:10px" @click="handleEdit(scope.row)"/>
            </el-tooltip>
            <el-tooltip v-premiSub="'生产设备_编辑','工作人员_编辑'" effect="dark" content="删除" placement="top">
              <img src="@/assets/images/dataManage/del.png"  @click="comfirm(scope.row)"/>
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <workerDialog :createVisible.sync="createVisible" :currentData="currentData" @handleSearch="handleSearch"/>
  </div>
</template>

<script>
import workerDialog from '../dialog/workerDialog.vue'
export default {
  name:"worker",
  components:{
    workerDialog
  },
  props:{
    tableList:{
      require:true,
      default:[]
    },
    statusType:{
      require:true
    },
    page:{
      default:1
    },
    size:{
      default:10
    }
  },
  data(){
    return{
      createVisible:false,
      currentData:{}
    }
  },
  methods:{
    getIndex(index) {
      const result = (this.page- 1) * this.size + index + 1
      return result < 10 ? `0${result}` : result
    },
    handleSelectionChange(val){
      let multipleSelection = val.map(row => row.id);
      this.$emit('update:multipleSelection',multipleSelection);
    },
    comfirm(row){
      this.$emit('comfirm',row)
    },
    changeStatus(val){
      this.$emit('update:statusType',val)
    },
    handleEdit(row){
      // this.currentData = row
      // this.createVisible = true
      this.$router.push({
        path:'editworker',
        query:{
          id:row.id
        }
      })
    },
    handleSearch(){
      this.$emit('handleSearch')
    }
  }
}
</script>

<style lang="scss">
.worker{
  .operation{
    font-size: 16px;
    display: flex;
    align-items: center;
    span{
      display: inline-block;
      margin-left: 10px;
    }
  }
  img{
    margin: 0 15px;
    cursor: pointer;
    width: 22px;
    height: 22px;
  }
}
</style>